import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Button, HeaderHero, NavBarColorSwitcher, Spacer, Form, WrapperBody } from "components";
export const _frontmatter = {
  "title": "Collaborate",
  "navBarColor": "light"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderHero title="A software house that fits" subtitle="Stop wasting time on agencies you need to train to understand your niche. Hire us instead." button={{
      children: "Fill the form",
      to: "#form"
    }} mdxType="HeaderHero" />
    <WrapperBody mdxType="WrapperBody">
	<NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
		<h2>(Only) tailored websites</h2>
		<p>
			We develop customized websites for your particular business needs,
			not just using a good looking template that could be a fit.
		</p>
		<p>
			This is because your website has to serve a purpose. Let's find out
			this purpose and deliver a software that helps you achieve your
			goals.
		</p>
		<blockquote>
			We don't develop websites; we design experiences in the digital
			world . — Department of Web
		</blockquote>
		<Button children="Fill the form" to="#form" mdxType="Button" />
		<h3>Our web development areas</h3>
		<ul>
			<li>Customized Software and Maintenance</li>
			<li>Custom WordPress Development</li>
			<li>Custom Web Applications </li>
			<li>Website Design & Re-design </li>
			<li>API Design & Implementation</li>
			<li>End-to-End Development</li>
		</ul>
		<p>Let's find out if we can help your business.</p>
		<h2>Tech stack</h2>
		<p>
			Every project is different and requires the technology that'll work
			in the particular scenario.
		</p>
		<p>Nonetheless we specialaze in those technologies:</p>
		<ul>
			<li>WordPress</li>
			<li>Laravel</li>
			<li>React</li>
		</ul>
		<p>
			Whether you're building a big portal or a small landing page, we'll
			choose the best tech and implement a fast, reliable website.
		</p>
		<h2>Up to you</h2>
		<p>
			As over 265 happily delivered projects show, we could be the best
			thing that happened to you.
		</p>
		<wbr id="form" />
		<p>Send the form - it's free!</p>
		<Form formsparkId="aYZWULzD" choices={["Design", "Design implementation (just code)", "End-to-End Development"]} confirmationMessage="Heck. You sent us a message!" privacyPolicyURL="https://departmentofweb.com/privacy-policy" mdxType="Form" />
	</NavBarColorSwitcher>
    </WrapperBody>
    <Spacer height={180} responsive={{
      md: 140,
      sm: 100
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      